import React, { useState } from 'react'
import './VideoCard.css'

const VideoCard = (item) => {
  const [data] = useState(item)
  if (data.item.snippet && data.item.id.kind === 'youtube#video') {
    const { title, description, thumbnails, publishTime } = data.item.snippet
    const date = new Date(publishTime)
    return (
      <div className="VideoCard">
        <a
          href={`https://www.youtube.com/watch?v=${data.item.id.videoId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={thumbnails.medium.url} alt={title} />
        </a>
        <div className="VideoDetails">
        <a
          href={`https://www.youtube.com/watch?v=${data.item.id.videoId}`}
          target="_blank"
          rel="noopener noreferrer"
        ><h4 dangerouslySetInnerHTML={{__html: title}}></h4></a>
          <p dangerouslySetInnerHTML={{__html: description}}></p>
          <p>Published: {date.toLocaleDateString('en-US')}</p>
        </div>
      </div>
    )
  } else {
    return <div />
  }
}

export default VideoCard
