import React from 'react'
import { Link } from 'react-router-dom'
//import { getImageSrc, getImageSrcset } from '../util/getImageUrl'
import { slugify } from '../util/url'
//import BackgroundImage from './BackgroundImage'
import './PostCard.css'

const PostCard = ({ postItem, className = '', ...props }) => {
  const date = new Date(postItem.date)

  const parseFilename = (filename) => {
    const parts = filename.match(/(.+)\.([\w]+)$/)
    return {
      filename: parts[1],
      extname: parts[2],
    }
  }

  const getImageSrcset = (path) => {
    const outputDir = '/images/uploads/'
    const resizedDir = '/images/uploads/resized/'
    const sizes = [600]
    if (!path || path.match(/^http/) || path.match(/svg$/) || window.CMS) {
      return null
    }
    const { filename } = parseFilename(path)
    const pathname = encodeURI(filename.replace(outputDir, resizedDir))
  
    const srcset = sizes.map((size) => `${`${pathname}.${size}.webp`}`)
    return srcset
  }

  return (
    <Link
      to={slugify(`/articles/${postItem.title}/`)}
      className={`PostCard ${className}`}
      {...props}
    >
      {postItem.postFeaturedImage && (
        <div className="PostCard--Image relative">
          <picture>
            <source
              srcSet={getImageSrcset(postItem.postFeaturedImage)}
              alt={postItem.title}
              type="image/webp"
            />
            <img
              className="Content--Image"
              src={postItem.postFeaturedImage}
              alt={postItem.title}
            />
          </picture>
        </div>
      )}
      {postItem.category && (
        <div className="PostCard--Category">{postItem.category}</div>
      )}
      <div className="PostCard--Content">
        {postItem.title && (
          <h3 className="PostCard--Title">{postItem.title}</h3>
        )}
        {postItem.excerpt && (
          <div className="PostCard--Excerpt">
            {postItem.excerpt.length > 160
              ? postItem.excerpt.slice(0, 157) + '...'
              : postItem.excerpt}
          </div>
        )}
        {postItem.date && (
          <p className="PostCard--Date">
            Published: {date.toLocaleDateString('en-US')}
          </p>
        )}
      </div>
    </Link>
  )
}

export default PostCard
