import React, { useState, useEffect } from 'react'
import Zoom from 'react-medium-image-zoom'
import './Product.css'

const Product = ({ id, alt, title, desc, variants }) => {
  const parseFilename = (filename) => {
    const parts = filename.match(/(.+)\.([\w]+)$/)
    return {
      filename: parts[1],
      extname: parts[2],
    }
  }
  const getImageSrcset = (path) => {
    const outputDir = '/images/uploads/'
    const resizedDir = '/images/uploads/resized/'
    const sizes = [1200]
    if (!path || path.match(/^http/) || path.match(/svg$/) || window.CMS) {
      return null
    }
    const { filename } = parseFilename(path)
    const pathname = encodeURI(filename.replace(outputDir, resizedDir))

    const srcset = sizes.map((size) => `${`${pathname}.${size}.webp`}`)
    return srcset
  }

  const [choice, setChoice] = useState(variants[0])
  const [size, setSize] = useState(variants[0].size)
  const [color, setColor] = useState(variants[0].color)
  const [hover, setHover] = useState(false)
  const [sizes, setSizes] = useState([])
  const [colors, setColors] = useState([])

  useEffect(() => {
    const getSizes = () => {
      let sizes = []
      variants.map((variant) =>
        variant.size ? sizes.push(variant.size) : null
      )
      return [...new Set(sizes)].map((i) => {
        return { value: i, label: i }
      })
    }
    const getColors = () => {
      let colors = []
      variants.map((variant) =>
        variant.color ? colors.push(variant.color) : null
      )
      return [...new Set(colors)].map((i) => {
        return { value: i, label: i }
      })
    }

    setSizes(getSizes())
    setColors(getColors())
  }, [variants])

  useEffect(() => {
    let choice = variants.find(
      (element) => element.size === size && element.color === color
    )
    setChoice(choice)
  }, [size, color, variants])

  return (
    <div className="Product">
      <div className="ImageContainer">
        <Zoom>
          <picture>
            <source
              srcSet={getImageSrcset(
                !hover && choice.altImage ? choice.image : choice.altImage
              )}
              alt={alt}
              type="image/webp"
            />
            <img
              className="Product--Image"
              src={!hover && choice.altImage ? choice.image : choice.altImage}
              alt={alt}
            />
          </picture>
        </Zoom>
        {choice.image !== choice.altImage && (
          <button
            onClick={() => setHover(!hover)}
            className="Image--ReverseButton"
          >
            Show reverse
          </button>
        )}
      </div>
      <div>
        <h2 className="Product--Title">{title}</h2>
        <div className="Product--Content">
          {choice.price && (
            <span>
              <strong className="Product--Price">{choice.price}</strong>
            </span>
          )}
          {desc && <div className="Product--Desc">{desc}</div>}

          <div className="Product--Buy">
            <form
              target="paypal"
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              className="Product--Vars"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value={id} />

              {sizes.length > 0 && (
              <><div className="preference"><label for="os0">Size</label><input type="hidden" name="on0" value="Size" />{" "}<select
                  name="os0"
                  value={size}
                  onChange={(e) => setSize(e.target.value)}
                >
                  {sizes.map((size, index) => (
                    <option key={index} value={size.value}>
                      {size.label}
                    </option>
                  ))}
                </select></div></>
              )}


              {colors.length > 0 && (
              <><div className="preference"><label for="os1">Color</label><input type="hidden" name="on1" value="Color" />{" "}<select
                  name="os1"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                >
                  {colors.map((color, index) => (
                    <option key={index} value={color.value}>
                      {color.label}
                    </option>
                  ))}
                </select></div></>
              )}

              <input type="hidden" name="currency_code" value="USD" />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif"
                border="0"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product
