const store = [
  {
    id: 'XXCX449X5R3QJ',
    title: 'Hunt Shoot Live T-shirt',
    alt: 'Hunt Shoot Live T-shirt',
    desc: '',
    variants: [
      {
        image: '/images/uploads/hsl_shirt_black.jpeg',
        altImage: '/images/uploads/hsl_shirt_black_2.jpeg',
        price: "$21.99",
        color: "Black",
        size: "Large",
        buyLink: ``,
      },
      {
        image: '/images/uploads/hsl_shirt_black.jpeg',
        altImage: '/images/uploads/hsl_shirt_black_2.jpeg',
        price: "$21.99",
        color: "Black",
        size: "XL",
        buyLink: ``,
      },
      {
        image: '/images/uploads/hsl_shirt_black.jpeg',
        altImage: '/images/uploads/hsl_shirt_black_2.jpeg',
        price: "$22.99",
        color: "Black",
        size: "XXL",
        buyLink: ``,
      },
      {
        image: '/images/uploads/hsl_shirt_grey.jpeg',
        altImage: '/images/uploads/hsl_shirt_grey_2.jpeg',
        price: "$21.99",
        color: "Grey",
        size: "Large",
        buyLink: ``,
      },
      {
        image: '/images/uploads/hsl_shirt_grey.jpeg',
        altImage: '/images/uploads/hsl_shirt_grey_2.jpeg',
        price: "$21.99",
        color: "Grey",
        size: "XL",
        buyLink: ``,
      },
      {
        image: '/images/uploads/hsl_shirt_grey.jpeg',
        altImage: '/images/uploads/hsl_shirt_grey_2.jpeg',
        price: "$22.99",
        color: "Grey",
        size: "XXL",
        buyLink: ``,
      },
    ],
  },
  {
    id: "3TGFWPBEHCYZ4",
    title: 'Feed the Machine T-shirt',
    alt: 'Feed the Machine T-shirt',
    desc: '',
    variants: [
      {
        image: '/images/uploads/feed_the_machine_grey.jpg',
        altImage: '/images/uploads/feed_the_machine_grey_2_cropped.jpg',
        price: "$24.99",
        color: "Green",
        size: "Large",
        buyLink: ``,
      },
      {
        image: '/images/uploads/feed_the_machine_grey.jpg',
        altImage: '/images/uploads/feed_the_machine_grey_2_cropped.jpg',
        price: "$24.99",
        color: "Green",
        size: "XL",
        buyLink: ``,
      },
      {
        image: '/images/uploads/feed_the_machine_grey.jpg',
        altImage: '/images/uploads/feed_the_machine_grey_2_cropped.jpg',
        price: "$25.99",
        color: "Green",
        size: "XXL",
        buyLink: ``,
      },
      {
        image: '/images/uploads/feed_the_machine_grey.jpg',
        altImage: '/images/uploads/feed_the_machine_grey_2_cropped.jpg',
        price: "$25.99",
        color: "Green",
        size: "XXXL",
        buyLink: ``,
      },
      {
        image: '/images/uploads/feed_the_machine_grey.jpg',
        altImage: '/images/uploads/feed_the_machine_grey_2_cropped.jpg',
        price: "$24.99",
        color: "Grey",
        size: "Large",
        buyLink: ``,
      },
      {
        image: '/images/uploads/feed_the_machine_grey.jpg',
        altImage: '/images/uploads/feed_the_machine_grey_2_cropped.jpg',
        price: "$24.99",
        color: "Grey",
        size: "XL",
        buyLink: ``,
      },
      {
        image: '/images/uploads/feed_the_machine_grey.jpg',
        altImage: '/images/uploads/feed_the_machine_grey_2_cropped.jpg',
        price: "$25.99",
        color: "Grey",
        size: "XXL",
        buyLink: ``,
      },
      {
        image: '/images/uploads/feed_the_machine_grey.jpg',
        altImage: '/images/uploads/feed_the_machine_grey_2_cropped.jpg',
        price: "$25.99",
        color: "Grey",
        size: "XXXL",
        buyLink: ``,
      },
    ],
    colors: null,
    sizes: null,
  },
  {
    id: "W6KKKKGDM94XU",
    title: 'Rifle Scope Ruler',
    alt: 'Rifle Scope Ruler',
    desc: '',
    variants: [
      {
        image: '/images/uploads/rifle_scope_ruler.jpeg',
        altImage: '/images/uploads/rifle_scope_ruler.jpeg',
        price: '$9.99',
        color: null,
        size: null,
        buyLink: `
        <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
<input type="hidden" name="cmd" value="_s-xclick">
<input type="hidden" name="hosted_button_id" value="W6KKKKGDM94XU">
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
</form>
      `,
      },
    ],
  },
]

export default store
