import React from 'react'

import PageHeader from '../components/PageHeader'
import Product from '../components/Product'
import Content from '../components/Content.js'
import './Store.css'
import StoreContent from "../store"

export default ({ fields }) => {
  const { title, subtitle, featuredImage, section1 } = fields
  return (
    <main>
      <div className='Store'>
        <PageHeader
          title={title}
          subtitle={subtitle}
          backgroundImage={featuredImage}
        />
        <div className='section'>
          <div className='container'>
            <Content source={section1} />
            <div className='Store--Listing'>
              {StoreContent.map((product, index) => <Product id={product.id} key={index} alt={product.alt} title={product.title} desc={product.desc} variants={product.variants} />)}
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
