import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Helmet from 'react-helmet'
import AnimatedRoutes from './components/AnimatedRoutes'
import RouteTransition from './components/RouteTransition'
import ScrollToTop from './components/ScrollToTop'
import Meta from './components/Meta'
import Home from './views/Home'
import About from './views/About'
import Store from './views/Store'
import Blog from './views/Blog'
import Media from './views/Media'
import SinglePost from './views/SinglePost'
import Contact from './views/Contact'
import NoMatch from './views/NoMatch'
import Nav from './components/Nav'
import Footer from './components/Footer'
import ServiceWorkerNotifications from './components/ServiceWorkerNotifications'
import data from './data.json'
import { slugify } from './util/url'
import { documentHasTerm, getCollectionTerms } from './util/collection'

const RouteWithMeta = ({ component: Component, ...props }) => (
  <RouteTransition 
    {...props}
    render={(routeProps) => (
      <Fragment>
        <Meta {...props} />
        <Component {...routeProps} {...props} />
      </Fragment>
    )}
  />
)

class App extends Component {
  state = {
    data,
  }

  getDocument = (collection, name) =>
    this.state.data[collection] &&
    this.state.data[collection].filter((page) => page.name === name)[0]

  getDocuments = (collection) => this.state.data[collection] || []

  render() {
    const globalSettings = this.getDocument('settings', 'global')
    const {
      siteTitle,
      siteUrl,
      siteDescription,
      socialMediaCard,
      headerScripts,
    } = globalSettings

    const posts = this.getDocuments('posts').filter(
      (post) => post.status !== 'Draft'
    )
    const categoriesFromPosts = getCollectionTerms(posts, 'categories')
    const postCategories = this.getDocuments('postCategories').filter(
      (category) =>
        categoriesFromPosts.indexOf(category.name.toLowerCase()) >= 0
    )

    return (
      <Router>
        <div className="React-Wrap">
          <ScrollToTop />
          <ServiceWorkerNotifications reloadOnUpdate />

          <Helmet
            defaultTitle={siteTitle}
            titleTemplate={`${siteTitle} | %s`}
          />
          <Meta
            headerScripts={headerScripts}
            absoluteImageUrl={
              socialMediaCard &&
              socialMediaCard.image &&
              siteUrl + socialMediaCard.image
            }
            twitterCreatorAccount={
              socialMediaCard && socialMediaCard.twitterCreatorAccount
            }
            twitterSiteAccount={
              socialMediaCard && socialMediaCard.twitterSiteAccount
            }
          />

          <Nav />
            <AnimatedRoutes exitBeforeEnter initial={false}>
              <RouteWithMeta
                path="/"
                exact
                component={Home}
                description={siteDescription}
                fields={this.getDocument('pages', 'home')}
              />
              <RouteWithMeta
                path="/store/"
                exact
                component={Store}
                fields={this.getDocument('pages', 'store')}
              />
              <RouteWithMeta
                path="/about/"
                exact
                component={About}
                fields={this.getDocument('pages', 'about')}
              />
              <RouteWithMeta
                path="/contact/"
                exact
                component={Contact}
                fields={this.getDocument('pages', 'contact')}
                siteTitle={siteTitle}
              />
              <RouteWithMeta
                path="/articles/"
                exact
                component={Blog}
                fields={this.getDocument('pages', 'articles')}
                posts={posts}
                postCategories={postCategories}
              />
              <RouteWithMeta
                path="/content/"
                exact
                component={Media}
                fields={this.getDocument('pages', 'content')}
              />
              {posts.map((post, index) => {
                const path = slugify(`/articles/${post.title}`)
                const nextPost = posts[index - 1]
                const prevPost = posts[index + 1]
                return (
                  <RouteWithMeta
                    key={path}
                    path={path}
                    exact
                    component={SinglePost}
                    fields={post}
                    nextPostURL={
                      nextPost && slugify(`/articles/${nextPost.title}/`)
                    }
                    prevPostURL={
                      prevPost && slugify(`/articles/${prevPost.title}/`)
                    }
                  />
                )
              })}

              {postCategories.map((postCategory) => {
                const slug = slugify(postCategory.title)
                const path = slugify(`/articles/category/${slug}`)
                const categoryPosts = posts.filter((post) =>
                  documentHasTerm(post, 'categories', slug)
                )
                return (
                  <RouteWithMeta
                    key={path}
                    path={path}
                    exact
                    component={Blog}
                    fields={this.getDocument('pages', 'articles')}
                    posts={categoryPosts}
                    postCategories={postCategories}
                  />
                )
              })}

              <Route render={() => <NoMatch siteUrl={siteUrl} />} />
            </AnimatedRoutes>
          <Footer />
        </div>
      </Router>
    )
  }
}

export default App
