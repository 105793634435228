import React from 'react'
import Facebook from '../images/facebook.svg'
import Instagram from '../images/instagram.svg'
import Youtube from '../images/youtube.svg'
import Email from '../images/email.svg'

import './Footer.css'

export default ({ globalSettings, socialSettings, navLinks }) => {
  const year = new Date().getFullYear()
  return (
    <footer className="Footer">
      <div className="container taCenter">
        <span>© {year} All rights reserved.</span>
      </div>
      <div className="container taCenter">
        <a
          href="mailto:zack@huntshootlive.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Email} alt="E-mail" />
        </a>
        <a
          href="https://www.youtube.com/huntshootlive"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Youtube} alt="Youtube" />
        </a>
        <a
          href="https://www.facebook.com/huntshootlive"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Facebook} alt="Facebook" />
        </a>
        <a
          href="https://www.instagram.com/huntshootlive"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Instagram} alt="Instagram" />
        </a>
      </div>
    </footer>
  )
}
