import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import PageHeader from '../components/PageHeader'
import VideoCard from '../components/VideoCard'

import dataJson from '../media.json'

import './Media.css'

export default ({ fields }) => {
  const { title, subtitle, featuredImage } = fields
  const [data, setData] = useState(dataJson.slice(0,10))
  
  const fetchMore = () => {
    console.log("Fetching")
    setData(dataJson.slice(0,10+data.length))
  }

  return (
    <main className="Media">
      <PageHeader
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
      />
      <div className="MediaList">
        {data && (
          <InfiniteScroll
            dataLength={data.length}
            next={fetchMore}
            hasMore={true}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {data.map((item, index) => (
              <VideoCard key={index} item={item} />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </main>
  )
}
