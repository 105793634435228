import React from 'react'

import Content from '../components/Content'
import Hero from '../components/Hero'
import VideoCard from '../components/VideoCard'
import Media from '../media.json'
import './Home.css'

export default ({ fields }) => {
  const { title, subtitle, featuredImage, body, logo } = fields
  return (
    <main className="Home">
      <Hero
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
        logo={logo}
      />

      <div className="section">
        <div className="container">
          <Content source={body} />
          <div className="latestVideo">
            <h2>Latest YouTube Video</h2>
            <VideoCard item={Media[0]} />
          </div>
        </div>
      </div>
    </main>
  )
}
