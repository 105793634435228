import React from 'react'
import Zoom from 'react-medium-image-zoom'
//import Marked from 'react-markdown'
import PropTypes from 'prop-types'

//import { getImageSrc } from '../util/getImageUrl'
import './Content.css'
import 'react-medium-image-zoom/dist/styles.css'
const ReactMarkdownWithHtml = require('react-markdown/with-html.js')

const encodeMarkdownURIs = (source = '') => {
  const markdownLinkRegex = /\[(?:\[[^\]]*\]|[^[\]])*\]\([ \t]*<?((?:\([^)]*\)|[^()])*?)>?[ \t]*(['"].*?\6[ \t]*)?\)/g
  return source.replace(markdownLinkRegex, (match, linkURI) => {
    if (!linkURI) return match
    const replaced = match.replace(linkURI, encodeURI(linkURI))
    return replaced
  })
}

const parseFilename = (filename) => {
  const parts = filename.match(/(.+)\.([\w]+)$/)
  return {
    filename: parts[1],
    extname: parts[2],
  }
}

const getImageSrcset = (path) => {
  const outputDir = '/images/uploads/'
  const resizedDir = '/images/uploads/resized/'
  const sizes = [600]
  if (!path || path.match(/^http/) || path.match(/svg$/) || window.CMS) {
    return null
  }
  const { filename } = parseFilename(path)
  const pathname = encodeURI(filename.replace(outputDir, resizedDir))

  const srcset = sizes.map((size) => `${`${pathname}.${size}.webp`}`)
  return srcset
}

const ImageWithSrcset = ({ nodeKey, src, alt, ...props }) => {
  const decodedSrc = decodeURI(src)
  const srcsets = getImageSrcset(decodedSrc)
  return (
    <Zoom>
      <picture className="Content--Image">
        {srcsets.map((item) => (
          <source
            srcSet={item}
            alt={alt}
            type="image/webp"
          />
        ))}
        <img className="Content--Image" {...props} src={decodedSrc} alt={alt} />
      </picture>
    </Zoom>
  )
}

const HtmlBlock = ({ value }) => {
  if (value.indexOf('<iframe') !== 0) return value
  return (
    <div
      className={`Content--Iframe`}
      dangerouslySetInnerHTML={{
        __html: value,
      }}
    />
  )
}

const Content = ({ source, src, className = '' }) => (
  <ReactMarkdownWithHtml
    children={encodeMarkdownURIs(source)}
    allowDangerousHtml
    renderers={{
      image: ImageWithSrcset,
      html: HtmlBlock,
    }}
  />
  // <ReactMarkdownWithHtml
  //   className={`Content ${className}`}
  //   source={encodeMarkdownURIs(source || src)}
  //   renderers={{
  //     image: ImageWithSrcset,
  //     html: HtmlBlock

  //   }}
  //    allowDangerousHTML
  //    skipHtml={false}
  // />
)

Content.propTypes = {
  source: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string,
}

export default Content
