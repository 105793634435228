import React from 'react'
import PropTypes from 'prop-types'

import BackgroundImage from './BackgroundImage'
import LazyImage from './LazyImage'
import './Hero.css'

const Hero = ({
  title,
  subtitle,
  backgroundImage,
  logo,
  large,
  className = ''
}) => {
  if (large) className += ' Hero-large'
  return (
    <div className={`Hero relative ${className}`}>
      {backgroundImage && (
        <BackgroundImage src={backgroundImage} opacity={0.4} lazy />
      )}
      <div className='container'>
        <LazyImage src="images/uploads/Badge.svg" lazy={true} alt="HSL" />
      </div>
    </div>
  )
}
Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default Hero
